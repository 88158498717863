import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../../layout';
import { HeadWrapper } from '../../layout/HeadWrapper';
import { morphism } from 'morphism';
import { TemplateBlocksZone } from '../../components/TemplateBlocksZone';
import { schemaBaseTemplate } from '../../morphismSchema/pages/schemaBaseTemplate';

export const Head = ({ data, pageContext, location }: any) => {
  return (
    <HeadWrapper
      data={data}
      pageContext={pageContext}
      location={location}
      templateName="SecurityPage"
      dataName={'datoCmsSecurityPage'}
      largestContentfullPaintImg={null}
    />
  );
};

export const query = graphql`
  query DatoSecurityPage($locale: String, $id: String) {
    datoCmsSecurityPage(locale: $locale, id: { eq: $id }) {
      ...SecurityPage
    }
  }
`;

const SecurityPage = ({ data, pageContext }: any) => {
  const doc = data.datoCmsSecurityPage;
  const _data = morphism(schemaBaseTemplate, {
    ...doc,
    pageContext,
  });

  if (!doc) return <></>;

  return (
    <Layout pageContext={pageContext} data={doc} templateName="SecurityPage">
      {_data?.blocks.length > 0 && <TemplateBlocksZone {..._data} />}
    </Layout>
  );
};

export default SecurityPage;
